import { AnimatePresence } from "framer-motion";
import React from "react";

import CardClub from "./cards/CardClub";
import CardRestaurant from "./cards/CardRestaurant";
import CardBatteries from "./cards/CardBatteries";
import CardPets from "./cards/CardPets";
import CardEmt from "./cards/CardEmt";
import CardSecurity from "./cards/CardSecurity";
import CardOil from "./cards/CardOil";

const ServiciosHomeCard = ({ id }) => {
  let ElementToDisplay;
  switch (id) {
    case "service-club":
      ElementToDisplay = CardClub;
      break;
    case "service-restaurant":
      ElementToDisplay = CardRestaurant;
      break;
    case "service-batteries":
      ElementToDisplay = CardBatteries;
      break;
    case "service-pets":
      ElementToDisplay = CardPets;
      break;
    case "service-security":
      ElementToDisplay = CardSecurity;
      break;
    case "service-emt":
      ElementToDisplay = CardEmt;
      break;
    case "service-oil":
      ElementToDisplay = CardOil;
      break;
    default:
      ElementToDisplay = null;
  }

  return (
    <AnimatePresence>
      {ElementToDisplay && <ElementToDisplay />}
    </AnimatePresence>
  );
};

export default ServiciosHomeCard;
