import { motion } from "framer-motion";
import GalleryHome from "../components/GalleryHome";

const Gallery = () => {
  return (
    <motion.div
      id="gallery"
      initial={{ y: 0, opacity: 0 }}
      animate={{ y: 120, opacity: 1 }}
      exit={{ y: 100, opacity: 0 }}
      transition={{ duration: 1 }}
    >
      <GalleryHome />
    </motion.div>
  );
};

export default Gallery;
