import React, { useEffect, useRef } from "react";
import { motion, useInView } from "framer-motion";

const TextComponent = ({ children, text }) => {
  const ref = useRef(null);
  const inView = useInView(ref, { once: true });
  // useEffect(() => {
  //   console.log("text in view >>> ", text, inView);
  // }, [inView]);
  return (
    <>
      <motion.span
        className="text-block"
        ref={ref}
        initial={{ x: 200, opacity: 0 }}
        animate={inView ? { x: 0, opacity: 1 } : { x: 200, opacity: 0 }}
        transition={{ delay: 0.2, duration: 0.5 }}
        style={{ position: "relative" }}
      >
        {text}
      </motion.span>
    </>
  );
};

export default TextComponent;
