import React, { useEffect, useRef, useState } from "react";
import Img1 from "../media/gallery/gallery-img1.jpg";
import Img2 from "../media/gallery/gallery-img2.jpg";
import Img3 from "../media/gallery/gallery-img3.jpg";
import Img4 from "../media/gallery/gallery-img4.jpg";
import Img5 from "../media/gallery/gallery-img5.jpg";
import Img6 from "../media/gallery/gallery-img6.jpg";
import Img7 from "../media/gallery/gallery-img7.jpg";
import Img8 from "../media/gallery/gallery-img8.jpg";
import Img9 from "../media/gallery/gallery-img9.jpg";
import Img10 from "../media/gallery/gallery-img10.jpg";
import "../styling/gallery-home.scss";
import { motion, AnimatePresence, useInView } from "framer-motion";

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

const gallery = [
  {
    id: 1,
    image: Img1,
    src: Img1,
    area: "1 / 1 / 2 / 3",
  },
  {
    id: 2,
    image: Img2,
    src: Img2,
    area: "1 / 3 / 3 / 4",
  },
  {
    id: 3,
    image: Img3,
    src: Img3,
    area: "1 / 4 / 2 / 5",
  },
  {
    id: 4,
    image: Img4,
    src: Img4,
    area: "2 / 1 / 2 / 2",
  },
  {
    id: 5,
    image: Img5,
    src: Img5,
    area: "2 / 2 / 2 / 3",
  },
  {
    id: 6,
    image: Img6,
    src: Img6,
    area: "2 / 4 / 2 / 5",
  },
  {
    id: 7,
    image: Img7,
    src: Img7,
    area: "3 / 1 / 4 / 4",
  },
  {
    id: 8,
    image: Img8,
    src: Img8,
    area: "3 / 4 / 5 / 5",
  },
  {
    id: 9,
    image: Img9,
    src: Img9,

    area: "4 / 1 / 4 / 2",
  },
  {
    id: 10,
    image: Img10,
    src: Img10,
    area: "4 / 2 / 6 / 4",
  },
];
const GalleryHome = () => {
  const [selectedId, setSelectedId] = useState(null);
  useEffect(() => {
    console.log("selectedId >>>> ", selectedId);
  }, [selectedId]);

  return (
    <div id="gallery" className="gallery">
      <div className="wrapper">
        <h2>Galería</h2>
        <div className="gallery-content">
          {gallery.map((img, index) => {
            return (
              <React.Fragment key={`image-${img.id}`}>
                <GalleryImage
                  img={img}
                  setSelectedId={setSelectedId}
                  index={index}
                />
              </React.Fragment>
            );
          })}
        </div>
        <Lightbox
          open={selectedId != null ? true : false}
          close={() => setSelectedId(null)}
          index={selectedId}
          slides={gallery}
        />
      </div>
    </div>
  );
};

export default GalleryHome;

const GalleryImage = (props) => {
  let { img, setSelectedId, index } = props;
  const ref = useRef();
  const inView = useInView(ref, { threshold: 1 });
  let rnd = 0.2 + Math.random() * 0.6;
  return (
    // <AnimatePresence>
    <motion.img
      initial={{ opacity: 0 }}
      animate={inView ? { opacity: 1 } : { opacity: 0 }}
      exit={{ opacity: 0 }}
      transition={{ delay: rnd, duration: 0.6 }}
      ref={ref}
      style={{
        gridArea: img.area,
      }}
      src={img.image}
      alt={`image-${img.id}`}
      onClick={() => setSelectedId(index)}
    />
    // </AnimatePresence>
  );
};
