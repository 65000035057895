import React from "react";
import { Outlet } from "react-router-dom";
import Nav from "../components/Nav";
// import Widgets from "../components/Widgets";
// import FooterHome from "../components/FooterHome";
import { ReactLenis, useLenis } from "@studio-freight/react-lenis";

const Layout = () => {
  const lenis = useLenis(({ scroll }) => {
    // called every scroll
  });
  return (
    <>
      <nav>
        <Nav />
        {/* <Widgets /> */}
      </nav>
      <ReactLenis root>
        <Outlet />
      </ReactLenis>
    </>
  );
};

export default Layout;
