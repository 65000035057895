import React, { useEffect, useState } from "react";
import "../styling/servicios-home.scss";
import ServiciosHomeTitle from "./ServiciosHomeTitle";
import ServiciosHomeCard from "./ServiciosHomeCard";
import TextComponent from "./TextComponent";
import { isMobile } from "react-device-detect";
import { motion } from "framer-motion";

const services = [
  {
    title: "Club de Tenis Málaga",
    id: "club",
    // component: CardClub,
    text: "El Club de Tenis Málaga tuvo su origen, según noticias recientes, en 1890 y posteriormente el 18 de octubre de 1968 se trasladó a nuestras instalaciones actuales en Pinares de San Antón. Somos conscientes de la importancia del deporte sobre la salud y la formación de las personas. Tenemos un lugar de encuentro envidiable para disfrutar nuestro tiempo de ocio con quienes compartimos aficiones e inquietudes.",
  },
  {
    title: "Restaurante Shämbala",
    id: "restaurant",
    text: "El significado de Shämbala, de origen sánscrito, es “Lugar de Paz o Lugar de Silencio”. Una especie de paraíso de relax y tranquilidad habitado por seres muy evolucionados que conviven en perfecta armonía con la naturaleza y el universo. Para acceder al Restaurante Shämbala debes entrar al Club de Tenis Málaga.",
    link: "https://shambalapinares.es/",
    // component: CardRestaurant,
  },
  {
    title: "Reciclaje de pilas",
    id: "batteries",
    // component: CardBatteries,
    text: 'Aquí en la Oficina de la Comunidad PSA, en Avd. San Antón 18, tienes a tu disposición un "Contenedor para Reciclar tus Pilas". Puedes traerlas de 9:00h a 13:00h de lunes a viernes.',
  },
  {
    title: "Botellas para mascotas",
    id: "pets",
    text: "También aquí en la Oficina de la Comunidad PSA, en Avd. San Antón 18, puedes recoger “TU BOTELLA”, hasta agotar existencias, de 9:00h a 13:00h de lunes a viernes.",
    // component: CardPets,
  },
  {
    title: "Seguridad 24/7",
    id: "security",
    // component: CardSecurity,
    text: "COFER SEGURIDAD vigila la Comunidad de Propietarios Pinares de San Antón día y noche desde Junio de 2023. Como medida de precaución y prevención contra incendios, desde antes de  verano y hasta finales de Octubre, el Equipo COFER extrema la vigilancia ampliando las rondas nocturnas llegando hasta el Mirador del Monte San Antón.",
  },
  {
    title: "Transporte público EMT",
    id: "emt",
    text: "Desde el 31 de enero de 2019 la Línea 29 de la EMT, JARAZMÍN-EL PALO hasta PINARES DE SAN ANTÓN, funciona de lunes a viernes laborables manteniendo las horas de servicio. Las salidas en Jarazmín son las siguientes: 8:20, 9:30, 10:30, 11:30, 12:30, 16:20, 17:20 y 18:20. Además, se mantiene la salida de las 16:00h desde la calle Bolivia.",
    // component: CardEmt,
  },
  {
    title: "Reciclaje de aceites usados en PSA",
    id: "oil",
    text: "Para facilitar la eliminación del aceite usado por parte de los vecinos de la comunidad, continuamos ofreciendo el servicio centralizado de recogida y reciclaje de aceite y grasas vegetales que en su día se concertó con Resial. Aquellos vecinos que lo deseen pueden depositar (dentro del contenedor colocado en el exterior de la oficina de la Comunidad en Avd. San Antón 18) Ios residuos acumulados de aceite usado en BOTELLAS DE PLÅSTICO BIEN CERRADAS máximo de 2 litros.",
    // component: CardEmt,
  },
];

const ServiciosHome = () => {
  const [idInView, setIdInView] = useState(null);

  useEffect(() => {
    console.log("id in View >>> ", idInView);
  }, [idInView]);
  return (
    <>
      <div id="services" className="services">
        <div className="wrapper">
          <h2>Nuestra Comunidad al servicio de todos los Malagueños</h2>
          {!isMobile && (
            <div className="services-content">
              <div className="services-content-list">
                {services.map((service, index) => {
                  return (
                    <div
                      className="services-content-list-item"
                      key={`services-${service.title}`}
                    >
                      <ServiciosHomeTitle
                        id={`service-${service.id}`}
                        setIdInView={setIdInView}
                      >
                        <TextComponent text={service.title} />
                      </ServiciosHomeTitle>
                      {/* {idInView === `service-${service.id}` && ( */}

                      <motion.div
                        className="services-content-list-item-text"
                        initial={{ y: 20, opacity: 0 }}
                        animate={
                          idInView === `service-${service.id}`
                            ? { y: 0, opacity: 1 }
                            : { y: 40, opacity: 0 }
                        }
                        transition={{ duration: 0.3, delay: 0.4 }}
                      >
                        <TextComponent text={service.text} />
                        {service.link && (
                          <>
                            <br />
                            <br />
                            <a href={service.link} target="_blank">
                              Acceso a su web
                            </a>
                          </>
                        )}
                      </motion.div>

                      {/* )} */}
                    </div>
                  );
                })}
              </div>
              <div className="services-content-images">
                <div className="services-content-images-image">
                  <ServiciosHomeCard id={idInView} />
                </div>
              </div>
            </div>
          )}
          {isMobile && (
            <div className="services-content-mobile">
              {services.map((service, index) => {
                return (
                  <div
                    className="services-content-mobile-list-item"
                    key={`services-${service.title}`}
                  >
                    <h4>
                      <TextComponent text={service.title} />
                    </h4>
                    <TextComponent text={service.text} />
                    <ServiciosHomeCard id={`service-${service.id}`} />
                  </div>
                );
              })}
              <div className="services-content-images">
                <div className="services-content-images-image"></div>
              </div>
            </div>
          )}
        </div>
      </div>
      ;
    </>
  );
};

export default ServiciosHome;
