import React from "react";
import { motion } from "framer-motion";
import ImgLogo from "../../media/pets-logo.svg";
import ImgBg from "../../media/pets-bg.jpg";

const CardPets = () => {
  return (
    <motion.div
      initial={{ y: 20, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: -20, opacity: 0 }}
      transition={{ duration: 0.6 }}
      key="card-club"
      className="services-content-images-image-card"
    >
      <img
        className="services-content-images-image-card-bg"
        src={ImgBg}
        alt=""
      />
      {/* <motion.img
        initial={{ y: 10, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: -10, opacity: 0 }}
        transition={{ delay: 0.4, duration: 0.6 }}
        className="services-content-images-image-card-logo"
        src={ImgLogo}
        alt=""
      /> */}
    </motion.div>
  );
};

export default CardPets;
