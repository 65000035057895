import React from "react";
import { motion } from "framer-motion";
import Img1 from "../../media/club-tenis-img1.jpg";
import Img2 from "../../media/club-tenis-img2.jpg";
import Img3 from "../../media/club-tenis-img4.jpg";
import ImgLogo from "../../media/club-tenis-logo.png";
import ImgBg from "../../media/club-tenis-bg.jpg";

const CardClub = () => {
  return (
    <motion.div
      initial={{ y: 20, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: -20, opacity: 0 }}
      transition={{ duration: 0.6 }}
      key="card-club"
      className="services-content-images-image-card"
    >
      <img
        className="services-content-images-image-card-bg"
        src={ImgBg}
        alt=""
      />
      <motion.img
        initial={{ y: 10, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: -10, opacity: 0 }}
        transition={{ delay: 0.4, duration: 0.6 }}
        className="services-content-images-image-card-logo"
        src={ImgLogo}
        alt=""
      />
      <motion.img
        initial={{ x: -20, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: -20, opacity: 0 }}
        transition={{ delay: 0.6, duration: 0.6 }}
        className="services-content-images-image-card-image1"
        src={Img1}
        alt=""
      />
      <motion.img
        initial={{ x: -20, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: -20, opacity: 0 }}
        transition={{ delay: 0.9, duration: 0.6 }}
        className="services-content-images-image-card-image2"
        src={Img2}
        alt=""
      />
      <motion.img
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: -20, opacity: 0 }}
        transition={{ delay: 1.2, duration: 0.6 }}
        className="services-content-images-image-card-image3"
        src={Img3}
        alt=""
      />
    </motion.div>
  );
};

export default CardClub;
