import { motion } from "framer-motion";
// import GalleryHome from "../components/GalleryHome";

const CookiesAndPrivacity = () => {
  return (
    <motion.div
      id="cookies"
      initial={{ y: 0, opacity: 0 }}
      animate={{ y: 120, opacity: 1 }}
      exit={{ y: 100, opacity: 0 }}
      transition={{ duration: 1 }}
      style={{ padding: "6rem" }}
    >
      <h2>Política de cookies</h2>
      Una Cookie es un fichero que se descarga en tu ordenador al acceder a
      determinadas páginas web. Las cookies permiten a una página web, entre
      otras cosas, almacenar y recuperar información sobre los hábitos de
      navegación de un usuario o de su equipo y, dependiendo de la información
      que contenga y de la forma en que utilice su equipo, pueden utilizarse
      para reconocer al usuario. Las cookies son esenciales para el
      funcionamiento de internet, aportando innumerables ventajas en la
      prestación de servicios interactivos, facilitándole la navegación y
      usabilidad de nuestra web. La información que te proporcionamos a
      continuación, te ayudará a comprender los diferentes tipos de cookies.
      <h4>Tipos de Cookies</h4>
      Según la entidad que las gestione:
      <ul>
        <li>
          <strong>Cookies propias</strong>: son aquellas que se recaban por el
          propio editor para prestar el servicio solicitado por el usuario.
        </li>
        <li>
          <strong>Cookies de terceros</strong>: son aquellas que son recabadas y
          gestionadas por un tercero, éstas no se pueden considerar propias.
        </li>
      </ul>
      Según el plazo de tiempo que permanezcan activadas:
      <ul>
        <li>
          <strong>Cookies de sesión</strong>: recaban datos mientras el usuario
          navega por la red con la finalidad de prestar el servicio solicitado.
        </li>
        <li>
          <strong>Cookies persistentes</strong>: se almacenan en el terminal y
          la información obtenida, será utilizada por el responsable de la
          cookie con la finalidad de prestar el servicio solicitado.
        </li>
      </ul>
      Según su finalidad:
      <ul>
        <li>
          <strong>Cookies técnicas</strong>: son las necesarias para la correcta
          navegación por la web.
        </li>
        <li>
          <strong>Cookies de personalización</strong>: permiten al usuario las
          características (idioma) para la navegación por el website.
        </li>
        <li>
          <strong>Cookies de análisis</strong>: permiten al prestador el
          análisis vinculado a la navegación realizada por el usuario, con la
          finalidad de llevar un seguimiento de uso de la página web, así como
          realizar estadísticas de los contenidos más visitados, número de
          visitantes, etc.
        </li>
        <li>
          <strong>Cookies publicitarias</strong>: permiten al editor incluir en
          la web espacios publicitarios, según el contenido de la propia web.
        </li>
        <li>
          <strong>Cookies de publicidad comportamental</strong>: permiten al
          editor incluir en la página web espacios publicitarios según la
          información obtenida a través de los hábitos de navegación del
          usuario.
        </li>
      </ul>
      Según lo dispuesto en el artículo 22.2 de la Ley 34/2002 de 11 de julio de
      Servicios de la Sociedad de la Información y de Comercio Electrónico
      (LSSI-CE), la Comunidad PSA informa de las cookies utilizadas en nuestro
      website se enmarcan en el siguiente cuadro:
      <table className="customtbl">
        <tbody>
          <tr className="middle">
            <td></td>
            <td>Cookies propias</td>
            <td>Cookies de terceros</td>
            <td>Cookies de sesión</td>
            <td>Cookies persistentes</td>
          </tr>
          <tr>
            <td>Cookies técnicas</td>
            <td>X</td>
            <td>X</td>
            <td>X</td>
            <td>X</td>
          </tr>
          <tr>
            <td>Cookies de personalización</td>
            <td>X</td>
            <td></td>
            <td>X</td>
            <td>X</td>
          </tr>
          <tr>
            <td>Cookies de análisis</td>
            <td></td>
            <td>X</td>
            <td>X</td>
            <td>X</td>
          </tr>
          <tr>
            <td>Cookies publicitarias</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>Cookies de publicidad comportamental</td>
            <td>X</td>
            <td>X</td>
            <td>X</td>
            <td>X</td>
          </tr>
        </tbody>
      </table>
      Asimismo, Comunidad PSA informa al usuario de que tiene la posibilidad de
      configurar su navegador de modo que se le informe de la recepción de
      cookies, pudiendo, si así lo desea, impedir que sean instaladas en su
      disco duro. Google Analytics almacena cookies para poder elaborar
      estadísticas sobre el tráfico y volumen de visitas de esta web. Al
      utilizar este sitio web está consintiendo el tratamiento de información
      acerca de usted por Google. Por tanto, el ejercicio de cualquier derecho
      en este sentido deberá hacerlo comunicando directamente con Google. Las
      cookies de Google Analytics se almacenan en servidores ubicados en Estados
      Unidos y se compromete a no compartirla con terceros, excepto en los casos
      en los que sea necesario para el funcionamiento del sistema o cuando la
      ley obligue a tal efecto. Según Google no guarda su dirección IP. Google
      Inc. es una compañía adherida al Acuerdo de Puerto Seguro que garantiza
      que todos los datos transferidos serán tratados con un nivel de protección
      acorde a la normativa europea. Puede consultar información detallada a
      este respecto a través del siguiente enlace:{" "}
      <a
        href="https://support.google.com/analytics/answer/6004245"
        target="_blank"
        rel="noopener"
        className="green"
      >
        https://support.google.com/analytics/answer/6004245
      </a>
      . Si lo deseas puedes utilizar el Complemento de inhabilitación para
      navegadores de Google Analytics a través de cuyas instrucciones pueden
      rechazarse las cookies analíticas de dicho servicio en todos los
      navegadores. Para más información{" "}
      <a
        href="https://tools.google.com/dlpage/gaoptout"
        target="_blank"
        rel="noopener"
        className="green"
      >
        https://tools.google.com/dlpage/gaoptout
      </a>
      .<h4>Configuración de las Cookies</h4>
      Asimismo, Comunidad PSA informa al usuario de que tiene la posibilidad de
      configurar su navegador de modo que se le informe de la recepción de
      cookies, pudiendo, si así lo desea, impedir que sean instaladas en su
      disco duro. A continuación te proporcionamos los enlaces de diversos
      navegadores, a través de los cuales podrás realizar dicha configuración:
      <ul>
        <li>
          <strong>Firefox</strong> desde aquí:{" "}
          <a
            className="green"
            href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias"
            target="_blank"
            rel="noopener"
          >
            ver enlace
          </a>
        </li>
        <li>
          <strong>Chrome</strong> desde aquí:{" "}
          <a
            className="green"
            href="https://support.google.com/chrome/answer/95647?hl=es"
            target="_blank"
            rel="noopener"
          >
            ver enlace
          </a>
        </li>
        <li>
          <strong>Internet Explorer</strong> desde aquí:{" "}
          <a
            className="green"
            href="https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies#ie=ie-10"
            target="_blank"
            rel="noopener"
          >
            ver enlace
          </a>
        </li>
        <li>
          <strong>Safari</strong> desde aquí:{" "}
          <a
            className="green"
            href="https://support.apple.com/kb/PH21411?locale=es_ES&amp;viewlocale=es_ES"
            target="_blank"
            rel="noopener"
          >
            ver enlace
          </a>
        </li>
        <li>
          <strong>Opera</strong> desde aquí:{" "}
          <a
            className="green"
            href="https://help.opera.com/Windows/11.50/es-ES/cookies.html"
            target="_blank"
            rel="noopener"
          >
            ver enlace
          </a>
        </li>
      </ul>
      <br></br>
      <br></br>
      <br></br>
      <h2>Política de privacidad</h2>
      <h4>Responsable de los datos</h4>
      El responsable de los datos recabados a través de este sitio web (de su
      URL principal y todos sus subdominios) y de todos los sitios referidos en
      este apartado (en conjunto, nuestros sitios web) es la Comunidad de
      Propietarios de Pinares de San Antón (en adelante, Comunidad PSA). El
      RESPONSABLE del tratamiento de los datos personales del USUARIO y te
      informa de que estos datos serán tratados de conformidad con lo dispuesto
      en el Reglamento (UE) 2016/679, de 27 de abril (GDPR), y la Ley Orgánica
      3/2018, de 5 de diciembre (LOPDGDD). Asimismo, te informamos que el
      objetivo de esta página es explicarte cómo recopilamos esa información,
      qué hacemos con ella y qué medidas de seguridad se toman. Además, esta
      política también tiene por objeto explicar qué derechos asisten al usuario
      y visitante de la web y cómo ejercerlos. Estamos comprometidos con la
      protección de tu privacidad online y queremos asegurarte que no se
      procesará indiscriminadamente ninguna información personal que nos
      proporciones. Dicho de otra forma y de conformidad con lo establecido en
      la legislación aplicable en materia de protección de datos, en concreto,
      el Reglamento (UE) 2016/679, de 27 de abril (GDPR), y la Ley Orgánica
      3/2018, de 5 de diciembre (LOPDGDD),  la Comunidad PSA, con domicilio
      social en Málaga [
      <em>
        por motivos de seguridad, el domicilio fiscal y el NIF no están
        visibles, envíanos un email a info@pinaresdesananton.net si necesitas
        conocerlos
      </em>
      ], se compromete a respetar la legislación vigente en materia de
      protección de datos personales y a adoptar las medidas jurídicas, técnicas
      y organizativas pertinentes para evitar así daños o pérdidas de los datos
      personales de nuestros clientes y usuarios. Es tu responsabilidad leer
      atentamente esta política de privacidad. Al visitar nuestro sitio web,
      aceptas las prácticas descritas en esta política de privacidad. Si no
      estás de acuerdo con esta política de privacidad y no deseas estar
      obligado por ella, no estás autorizado a acceder o utilizar el sitio web.
      <h4>¿Qué tipo de datos recogemos?</h4>
      Recogemos información personal cuando tú rellenas de forma voluntaria
      algún formulario online en cualquiera de nuestras páginas (como, por
      ejemplo, el de contacto). Las tipologías de datos que puedes encontrar en
      los formularios son, por ejemplo:
      <strong>Datos identificativos y de contacto</strong>: Cuando quieras
      hacernos una consulta, te pediremos que nos facilite información, como tu
      nombre (los apellidos son opcionales) y tu dirección de correo
      electrónico, para poder dirigirnos a ti correctamente. Por otro lado, hay
      datos personales anonimizados (no se puede identificar a la persona) que
      se recogen fruto de tu navegación (como IP, browser o tipo de
      dispositivo).
      <strong>Datos de preferencia</strong>: Siempre que lo hayas manifestado
      expresamente, podrás recibir comunicaciones segmentadas en base a tus
      intereses. Por ejemplo, si deseas estar al tanto de las últimas novedades
      y noticias en nuestros servicios, recibirás comunicaciones sobre estos
      temas de forma preferente. Los datos personales que nos proporciones serán
      utilizados solo para la finalidad que se te informó y que tú consentiste
      en el momento de la recogida.
      <h4>¿Para qué propósitos recogemos estos datos?</h4>
      <strong>
        Para proveerte de información o contenidos solicitados (por ejemplo, la
        Newsletters)
      </strong>
      : Cuando te suscribes a una de nuestras Newsletters, estás dando tu
      consentimiento para que usemos la dirección de correo electrónico
      proporcionada para enviarte la selección de contenido más relevante
      elegida por el equipo editorial de Comunidad PSA. Puedes retirar este
      consentimiento y darte de baja de estos en cualquier momento. Pondremos a
      tu disposición al final de cada email un link para poder darte de baja de
      forma automática.
      <strong>
        Para realizar análisis estadístico y monitorización del funcionamiento
        de nuestras páginas
      </strong>
      : algunos datos personales (como la IP) se recogen a través de cookies y
      se procesan de forma agregada (no se puede identificar a la persona).
      Suelen recogerse por medio de herramientas de analítica web que nos
      permite monitorizar el correcto funcionamiento de la página y saber, por
      ejemplo, qué contenidos han tenido más éxito.
      <strong>
        Gestionar solicitudes de consulta, información, quejas y reclamaciones
      </strong>
      : Cuando compartas tus datos con nosotros para alguna de estas
      finalidades, estaremos legitimados para gestionar tu solicitud y
      contestarle.
      <h4>¿Compartimos tu información con alguien más?</h4>
      La Comunidad PSA no cederá los datos personales de los Usuarios a ningún
      tercero sin una base jurídica que legitime ese tratamiento. Esto significa
      que no compartiremos tus datos personales con terceros salvo cuando exista
      una obligación legal, por ejemplo, que nos lo pidan las Fuerzas y Cuerpos
      de Seguridad del Estado, Jueces y Tribunales o la Agencia Tributaria … o
      para llevar a cabo la gestión de nuestros sorteos o concursos con las
      empresas organizadoras de los mismos (por ejemplo, para enviar un premio a
      un ganador). Por otra parte, podrán tener acceso a tus datos personales
      los encargados de tratamiento de Comunidad PSA, es decir, los prestadores
      de servicios que tienen que acceder a tus datos personales para poder
      desarrollar las funciones para las que fueron contratados. Además,
      queremos informarte de que con estos prestadores de servicios
      (habitualmente relacionados con servicios de tecnología y de marketing
      como el call center) se firman contratos de encargados de tratamiento que
      cubren todas las medidas de seguridad adecuadas para garantizar la
      protección de sus datos.
      <h4>¿Qué derechos tienes sobre tus datos?</h4>
      Si tienes una cuenta o has dejado comentarios en esta web, puedes
      solicitar recibir un archivo de exportación de los datos personales que
      tenemos sobre ti, incluyendo cualquier dato que nos hayas proporcionado.
      También puedes solicitar que eliminemos cualquier dato personal que
      tengamos sobre ti. Esto no incluye ningún dato que estemos obligados a
      conservar con fines administrativos, legales o de seguridad.
      <h4>¿Por cuánto tiempo conservaremos tus datos?</h4>
      En Comunidad PSA garantizamos que tus datos solo se conservarán durante el
      tiempo que sea necesario y acorde a la finalidad para la que se recabaron:
      Mientras estés suscrito a alguno de los servicios de la web, procesaremos
      tus datos de manera activa.
      <strong>Newsletter y otras comunicaciones por e-mail</strong>: Tras
      haberte suscrito a las Newsletter y/o otras comunicaciones por email, tus
      datos estarán activos vinculados a este servicio salvo que revoques su
      consentimiento. Hasta ese momento, tus datos se procesarán de forma
      activa. Cuando revoques su consentimiento o ejerzas tu derecho de
      supresión, tus datos pasarán a estar bloqueados y archivados durante un
      plazo de 5 años con el objetivo de poder responder a posibles
      responsabilidades vinculadas al tratamiento (es decir, los datos solo
      serían rescatados en caso de que hubiera que ponerlos a disposición de las
      autoridades competentes). Sin perjuicio de lo anterior, podrás ejercitar
      tu derecho de oposición o revocar tu consentimiento en cualquier momento,
      lo que dará lugar a el bloqueo de tus datos (que quedarán archivados y con
      acceso restringido con el único propósito de poder responder a
      requerimientos por parte de autoridades competentes).
      <h4>Automatización de perfiles</h4>
      En ocasiones puntuales, podemos pedir tu consentimiento para utilizar los
      datos que compartes con nosotros, para personalizar nuestros servicios o
      el contenido de los envíos de email marketing. Por ejemplo, podemos usar
      la información que nos has proporcionado para enviarte información
      preferente sobre servicios que creemos que te pueden interesar. Te
      solicitaremos tu consentimiento previamente para poder ejercitar este
      servicio personalizado. Si no deseas recibir estas comunicaciones, podrás
      darse de baja en cualquier momento.
      <h4>Hipervínculos (enlaces a otras páginas web)</h4>
      Nuestro sitio web y nuestro servidor, donde sea que se encuentre, tienen
      medidas técnicas y organizativas adecuadas para proteger tus datos contra
      el uso no autorizado o ilegal de tu información personal, así como la
      pérdida, destrucción o daño accidental de tu información personal mientras
      se encuentre bajo nuestro control. Nuestro sitio web puede contener
      enlaces a otros sitios administrados por otras organizaciones. Esta
      política de privacidad se aplica únicamente a nuestro sitio web, por lo
      que te recomendamos que leas las políticas de privacidad de cualquier otra
      página que visites. No somos responsables de las políticas y prácticas de
      privacidad de otras páginas web, incluso si accedes a ellas utilizando
      enlaces desde nuestro sitio web.
      <h4>Tus derechos</h4>
      Con la entrada en vigor del Reglamento 2016/679 del Parlamento Europeo y
      del Consejo de 27 de abril de 2016, nuestros usuarios podrán ejercitar sus
      derechos de forma gratuita y se harán efectivos en un plazo máximo de un
      mes desde la solicitud. De este modo, podrás proceder al ejercicio de los
      siguientes derechos:
      <ol>
        <li>
          <strong>Derecho de acceso</strong>: Los usuarios de Comunidad PSA
          pueden pedir que les confirmen si están siendo tratados o no datos
          personales que les conciernen. En el caso de que la respuesta sea
          afirmativa, tienen derecho a conocer con qué propósito se usan esos
          datos, qué tipo de datos se están procesando o a quién se han revelado
          esos datos, entre otros aspectos.
        </li>
        <li>
          <strong>Derecho de rectificación</strong>: los usuarios tienen derecho
          a que se modifiquen sin dilación aquellos datos recogidos por
          Comunidad PSA que sean inexactos o contengan algún error.
        </li>
        <li>
          <strong>Derecho de oposición</strong>: los usuarios tienen derecho a
          oponerse en cualquier momento a que sus datos personales sean objeto
          de tratamiento.
        </li>
        <li>
          <strong>Derecho de supresión</strong> (Derecho al olvido): Tú como
          usuario tienes derecho a solicitar la supresión sin dilación indebida
          de tus datos cuando concurra alguno de los supuestos contemplados. Por
          ejemplo, cuando se realice un tratamiento ilícito de datos o cuando
          haya desaparecido la finalidad que motivó su tratamiento o recogida.
        </li>
        <li>
          <strong>
            Derecho a no ser objeto de decisiones individualizadas
          </strong>
          : Tienes derecho a no ser objeto de una decisión basada únicamente en
          el tratamiento automatizado, incluida la elaboración de perfiles.
        </li>
        <li>
          <strong>Retirar el consentimiento</strong>: cuando se haya obtenido
          previamente el consentimiento, las personas tienen derecho a retirarlo
          en cualquier momento.
        </li>
        <li>
          <strong>
            Derecho a presentar una queja ante la Agencia Española de Protección
            de Datos
          </strong>
          : Si consideras que tus derechos han sido vulnerados, podrás
          interponer una reclamación ante la Agencia Española de Protección de
          Datos, con domicilio en calle Jorge Juan 6, 28001 de Madrid, teléfono
          901 100 099, y sitio web{" "}
          <a
            className="green"
            href="https://www.aepd.es/es"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.agpd.es
          </a>
        </li>
      </ol>
      <h4>Menores</h4>
      Nuestro sitio Web está dirigidos a mayores 16 años. Por este motivo, al
      cumplimentar cualquier formulario de registro garantizas que eres mayor de
      16 años y serás enteramente responsable de esta declaración. Podremos, en
      cualquier momento, requerirte que verifiques tu edad mediante la
      aportación del correspondiente documento identificativo. Bajo sospecha de
      que un usuario sea menor de esa edad, y de que ha falseado los datos que
      se requieren para su acceso, Comunidad PSA borrará el registro de dicho
      usuario y cancelará el acceso a los servicios ofrecidos. Además, Comunidad
      PSA anima a los padres o tutores de los menores a que supervisen las
      actividades electrónicas de sus hijos, por ejemplo, mediante el uso de
      herramientas de control parental que ayudan a proporcionar un entorno
      electrónico seguro para los menores.
      <h4>Cambios y actualizaciones de la política de privacidad</h4>
      Si en el futuro se produjeran cambios en nuestras prácticas y/o Política
      de Privacidad que pudieran afectar a tus datos personales te comunicaremos
      los cambios pertinentes a través de nuestro Sitio Web o de alguna otra
      manera, a nuestra elección. Dichos cambios tendrán validez desde el
      momento en el que sean anunciados. La Política de Privacidad se actualizó
      por última vez a fecha 1 de junio de 2022.
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
    </motion.div>
  );
};

export default CookiesAndPrivacity;
