import React, { useRef } from "react";
import "../styling/newhero.scss";
import newhero from "../media/new-hero.jpg";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import TextComponent from "./TextComponent";

gsap.registerPlugin(useGSAP);
const heroTitle = "Pinares de San Antón";
const heroText =
  "Caracterizado por sus tres cimas, siendo la ciudad por la cara sur la que pone sus límites tras haberle ganado terreno a esta ladera, que casi es bañada por el Mediterráneo. Posee una privilegiada situación frente al mar, que le favorece con el benefactor clima mediterráneo de la costa, y es contorneado por los arroyos Jabonero y Gálica.";

const splitTextIntoCharacters = (text) => {
  return text.split("").map((char, index) => {
    // Replace the space with a non-breaking space HTML entity
    const character = char === " " ? "\u00A0" : char;
    return (
      <span key={index} className="char">
        {character}
      </span>
    );
  });
};

const Hero = () => {
  const heroRef = useRef();
  const titleRef = useRef();
  const preTitleRef = useRef();
  const imageContainerRef = useRef();
  const imageRef = useRef();
  const heroTextRef = useRef();

  useGSAP(
    () => {
      let title = titleRef.current.children[0].children;
      let pretitle = preTitleRef.current.children[0].children;

      // console.log(titleRef);
      // console.log(imageContainerRef.current);
      // console.log(imageRef.current);
      const tl = gsap.timeline();
      tl.addLabel("startImageContainer", "+=1.5") // Delay start by 0.5 seconds
        .from(
          imageContainerRef.current,
          {
            y: 380,
            scale: 1.01,
            opacity: 0,
            duration: 2.8,
            delay: 0.5,
            ease: "elastic.out(1.05, 0.95)",
          },
          "startImageContainer"
        ) // Start at label
        .from(
          imageRef.current,
          {
            scale: 1.2,
            y: -100,
            duration: 2,
            delay: 0.2,
            ease: "power3.out",
          },
          "<"
        )
        .from(
          pretitle,
          {
            opacity: 0,
            y: 80,
            skewX: 20,
            skewY: 10,
            stagger: 0.03,
            duration: 1,
          },
          "<"
        )
        .from(
          title,
          {
            opacity: 0,
            y: 80,
            skewX: 20,
            skewY: 10,
            stagger: 0.03,
            duration: 0.8,
            delay: 0.4,
          },
          "<"
        )
        .from(
          heroTextRef.current,
          {
            opacity: 0,
            y: 20,
            duration: 0.8,
            delay: 1,
            ease: "power4.out",
          },
          "<"
        );
    },

    { scope: heroRef }
  );

  return (
    <>
      <div className="hero" ref={heroRef}>
        <div className="wrapper">
          <div className="hero-title">
            <div className="hero-pretitle-container" ref={preTitleRef}>
              <h3>{splitTextIntoCharacters("Comunidad")}</h3>
            </div>
            <div className="hero-title-container" ref={titleRef}>
              <h1>{splitTextIntoCharacters(heroTitle)}</h1>
            </div>
          </div>
          <div className="hero-image" ref={imageContainerRef}>
            <img
              src={newhero}
              ref={imageRef}
              alt="imagen-pinares-de-san-anton"
            />
          </div>
          <div className="hero-text" ref={heroTextRef}>
            <TextComponent text={heroText} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
