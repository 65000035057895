import React from "react";
import { motion } from "framer-motion";

const pageTransition = (OriginalComponent) => {
  return () => (
    <>
      <motion.div
        initial={{ y: 10, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: 20, opacity: 0 }}
        transition={{ duration: 0.4, ease: [0.22, 1, 0.36, 1] }}
      >
        <OriginalComponent />
      </motion.div>
    </>
  );
};

export default pageTransition;
