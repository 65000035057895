import React, { useRef } from "react";
import "../styling/old-hero.scss";
import hero1 from "../media/hero1.jpg";
import hero2 from "../media/hero2.jpg";
import hero3 from "../media/hero3.jpg";
import hero4 from "../media/hero4.jpg";
import { useScroll, motion, useTransform } from "framer-motion";
import TextComponent from "./TextComponent";

const heroGridImage = [
  {
    imageUrl: hero1,
    // top: -14,
  },
  {
    imageUrl: hero2,
    // top: -12,
  },
  {
    imageUrl: hero3,
    // top: -10,
  },
  {
    imageUrl: hero4,
    // top: -13,
  },
];

const heroTitle = "Pinares de San Antón";
const heroText =
  "Caracterizado por sus tres cimas, siendo la ciudad por la cara sur la que pone sus límites tras haberle ganado terreno a esta ladera, que casi es bañada por el Mediterráneo. Posee una privilegiada situación frente al mar, que le favorece con el benefactor clima mediterráneo de la costa, y es contorneado por los arroyos Jabonero y Gálica.";

const OldHero = () => {
  return (
    <>
      <div className="hero">
        <div className="wrapper">
          <HeroTitle />
          <div className="hero-image">
            {heroGridImage.map((heroImg, index) => {
              return (
                <React.Fragment key={`hero-img=${index}`}>
                  <HeroImageItem heroImg={heroImg} index={index} />
                </React.Fragment>
              );
            })}
          </div>
          <div className="hero-text">
            <TextComponent text={heroText} />
            {/* <div dangerouslySetInnerHTML={{ __html: heroText }}></div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default OldHero;

const HeroTitle = () => {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["0 0.3", "0.2 1"],
  });
  const scaleProgress = useTransform(scrollYProgress, [1, 0], [1, 0.9]);

  return (
    <div className="hero-title" ref={ref}>
      <motion.h3
        style={{
          transformOrigin: "left",
          scale: scaleProgress,
        }}
      >
        Comunidad
      </motion.h3>
      <motion.h1
        style={{
          transformOrigin: "left",
          scale: scaleProgress,
        }}
      >
        {heroTitle}
      </motion.h1>
    </div>
  );
};

function getRandomValue() {
  // Math.random() generates a random value between 0 (inclusive) and 1 (exclusive)
  // Multiply by the range (0.4 - 0.1) and add the minimum value (0.1)
  return 0.2 + Math.random() * 0.8;
  // return randomValue.toFixed(2); // Optionally round to 2 decimal places
}
function getRandomPos() {
  // Math.random() generates a random value between 0 (inclusive) and 1 (exclusive)
  // Multiply by the range (0.4 - 0.1) and add the minimum value (0.1)
  return -5 - Math.random() * 8;
  // return randomValue.toFixed(2); // Optionally round to 2 decimal places
}
const HeroImageItem = ({ heroImg, index }) => {
  let { imageUrl } = heroImg;
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["0 0.3", "0.2 1"],
  });
  let rnd = getRandomValue();
  let rndPos = getRandomPos();
  // const scaleProgress = useTransform(scrollYProgress, [1, 0], [0.5, 1]);
  const topProgress = useTransform(
    scrollYProgress,
    [0.3, 1],
    [0.5, rndPos * 50]
  );
  const opacityProgress = useTransform(scrollYProgress, [1, 0], [0.2, 1]);

  return (
    <motion.div
      className="hero-image-item"
      ref={ref}
      style={{
        position: "relative",
        y: topProgress,
        opacity: opacityProgress,
      }}
      initial={{ scale: 0.76 }}
      animate={{ scale: 1 }}
      transition={{ duration: 0.8, delay: rnd }}
    >
      <motion.img
        style={{
          y: -topProgress,
        }}
        initial={{ opacity: 0, scale: 0.76 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.8, delay: rnd }}
        key={`heroImage-${index}`}
        src={imageUrl}
        alt={`Vistas pinares de san anton${index}`}
      />
    </motion.div>
  );
};
