import React, { useState } from "react";
import "../styling/contact-home.scss";

const FORMSPARK_ACTION_URL = "https://submit-form.com/b0JlxpCes";

const ContactoHome = () => {
  return (
    <div id="contact" className="contact">
      <div className="contact-wrapper">
        <div className="contact-text">
          <h2>Cuéntanos algo</h2>
          <p>
            Tienes alguna sugerencia o algo que comentar sobre la comunidad de
            Pinares San Anton? Envíanos un mensaje y lo estudiaremos.
          </p>
        </div>
        <div className="contact-form">
          <ContactForm />
        </div>
      </div>
    </div>
  );
};

export default ContactoHome;

export const ContactForm = () => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formSending, setFormSending] = useState(false);

  const [mailBody, setMailBody] = useState();

  const checkInputs = (input, value) => {
    let updatedMailBody = { ...mailBody };
    switch (input) {
      case "name":
        // console.log("name checks...", value);
        // setName(value);
        updatedMailBody.nombre = value;
        break;
      case "tel":
        // console.log("phone checks...", value);
        // setPhone(value);
        updatedMailBody.telefono = value;
        break;
      case "email":
        // console.log("email checks...", value);
        updatedMailBody.email = value;
        // setEmail(value);
        break;
      case "message":
        // console.log("text checks...", value);
        updatedMailBody.mensaje = value;
        // setMessage(value);
        break;
      default:
    }
    setMailBody(updatedMailBody);
  };

  const onSubmit = async (e) => {
    setFormSending(true);
    e.preventDefault();

    await fetch(FORMSPARK_ACTION_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        mailBody,
      }),
    });
    // alert("Form submitted");
    setFormSending(false);
    setFormSubmitted(true);
  };

  return (
    <div className="contact-box-form">
      {!formSubmitted && !formSending && (
        <form onSubmit={onSubmit}>
          <div className="contact-form">
            <div className="contact-form-row">
              <div className="contact-form-item">
                <label htmlFor="name">Nombre</label>
                <input
                  type="text"
                  id="name"
                  placeholder=" "
                  name="name"
                  required
                  autoComplete="name"
                  onChange={(e) => checkInputs(e.target.id, e.target.value)}
                />
              </div>

              <div className="contact-form-item">
                <label htmlFor="email">Correo electrónico</label>
                <input
                  type="email"
                  id="email"
                  placeholder=" "
                  name="email"
                  required
                  autoComplete="email"
                  onChange={(e) => checkInputs(e.target.id, e.target.value)}
                />
              </div>
            </div>
            <div className="contact-form-row">
              <div className="contact-form-item">
                <label htmlFor="tel">Teléfono</label>
                <input
                  type="tel"
                  id="tel"
                  placeholder=" "
                  name="tel"
                  autoComplete="tel"
                  onChange={(e) => checkInputs(e.target.id, e.target.value)}
                />
              </div>
              <div className="contact-form-item">
                <label htmlFor="message">Asunto</label>
                <textarea
                  id="message"
                  autoComplete="message"
                  name="message"
                  placeholder=" "
                  required
                  onChange={(e) => checkInputs(e.target.id, e.target.value)}
                ></textarea>
              </div>
            </div>

            <div className="contact-form-item">
              <button className="button-cta-inverted" type="submit">
                <p className="button-text">Enviar</p>
              </button>
            </div>
          </div>
        </form>
      )}
      {formSending && (
        <div className="formSending">
          <span className="loader"></span>
        </div>
      )}
      {formSubmitted && (
        <div className="formSubmitted">
          Gracias por enviarnos tu mensaje, lo leeremos y nos pondremos en
          contacto contigo si es necesario.
        </div>
      )}
    </div>
  );
};
