import React, { useEffect, useRef } from "react";
import { useInView } from "framer-motion";

const ServiciosHomeTitle = ({ children, id, setIdInView }) => {
  const ref = useRef();
  const inView = useInView(ref, {
    // margin: "-44% 0px -44% 0px",
    margin: "-35% 0px -35% 0px",
  });
  useEffect(() => {
    // inView && console.log(ref.current.id);
    inView && setIdInView(ref.current.id);
  }, [inView]);
  return (
    <h4 ref={ref} className={`${inView ? "text-active" : " "}`} id={id}>
      {children}
    </h4>
  );
};

export default ServiciosHomeTitle;
