import Hero from "../components/Hero";
import OldHero from "../components/OldHero";
import Contact from "./Contact";
import SobreNosotrosHome from "../components/SobreNosotrosHome";
import UnicosHome from "../components/UnicosHome";
import ServiciosHome from "../components/ServiciosHome";
import GalleryHome from "../components/GalleryHome";
import { isMobile } from "react-device-detect";

import QuienesSomosHome from "../components/QuienesSomosHome";
import { motion } from "framer-motion";
import pageTransition from "../components/pageTransition";
const Home = () => {
  return (
    <motion.div>
      {/* <div
        className="spacer"
        style={{ height: isMobile ? "50vh" : "60vh" }}
      ></div> */}
      {/* <OldHero /> */}
      <Hero />
      <SobreNosotrosHome />
      <QuienesSomosHome />
      <UnicosHome />
      <ServiciosHome />
      <GalleryHome />
      <Contact />
    </motion.div>
  );
};

export default pageTransition(Home);
