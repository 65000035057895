import { motion } from "framer-motion";
import ServiciosHome from "../components/ServiciosHome";

const Services = () => {
  return (
    <motion.div
      id="services"
      initial={{ y: 0, opacity: 0 }}
      animate={{ y: 120, opacity: 1 }}
      exit={{ y: 100, opacity: 0 }}
      transition={{ duration: 1 }}
    >
      <ServiciosHome />
    </motion.div>
  );
};

export default Services;
