import { motion } from "framer-motion";
import pageTransition from "../components/pageTransition";
import SobreNosotrosPage from "../components/SobreNosotrosPage";

const SobreNosotros = () => {
  return (
    <motion.div>
      <div className="wrapper">
        <div className="spacer" style={{ height: "20vh" }}></div>
        <SobreNosotrosPage />
        <div className="spacer" style={{ height: "20vh" }}></div>
      </div>
    </motion.div>
  );
};

export default pageTransition(SobreNosotros);
