import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import SobreNosotros from "./pages/SobreNosotros";
import Contact from "./pages/Contact";
import NoPage from "./pages/NoPage";
import { AnimatePresence } from "framer-motion";
import Gallery from "./pages/Gallery";
import FooterHome from "./components/FooterHome";
import CookiesAndPrivacity from "./pages/Cookies";
import Services from "./pages/Services";

function App() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return (
    <>
      <Layout />
      <AnimatePresence mode="wait">
        <Routes location={location} key={location.pathname}>
          <Route index element={<Home />} />
          <Route path="/sobre-nosotros" element={<SobreNosotros />} />
          <Route path="/servicios" element={<Services />} />
          <Route path="/galeria" element={<Gallery />} />
          <Route path="/contacto" element={<Contact />} />
          <Route
            path="/cookies-y-privacidad"
            element={<CookiesAndPrivacity />}
          />
          <Route path="*" element={<NoPage />} />
        </Routes>
        <footer>
          <FooterHome />
        </footer>
      </AnimatePresence>
    </>
  );
}

export default App;
