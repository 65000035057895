import React from "react";
import UnicosHome from "./UnicosHome";
import TextComponent from "./TextComponent";
import LinkArrow from "../media/link-arrow.svg";
import { Link } from "react-router-dom";
import LibretoBook from "../media/libreto.jpg";
import "../styling/about-page.scss";
import ImageComponent from "./ImageComponent";

const libretoText1 =
  "Con un entusiasmo desbordante les presento una obra excepcional que nos sumerge en la fascinante historia del Monte de San Antón, en la querida barriada de El Palo, Málaga.";
const libretoText2 =
  "El incomparable José Antonio Barberá nos regala este increíble libreto, una verdadera joya literaria que nos lleva de la mano a explorar los misterios y maravillas de este icónico monte. Imaginen caminar por los senderos ancestrales, rodeados de la exuberante naturaleza que guarda siglos de historias y leyendas. Cada página de este libro está impregnada del espíritu y la esencia de este lugar sagrado, revelando los secretos mejor guardados y las tradiciones que han marcado generaciones.";
const libretoText3 =
  "¿Están preparados para una aventura inolvidable en las alturas? Descarguen ahora mismo este tesoro literario y déjense cautivar por la magia del Monte de San Antón.";
const libretoText4 =
  "¡No se pierdan la oportunidad de explorar este fascinante rincón de Málaga y descubrir su legado único e inigualable!";

// const comundidadText1 = "";
// const comundidadText2 = "";
// const comundidadText3 = "";
// const comundidadText4 = "";

const SobreNosotrosPage = () => {
  return (
    <div className="about">
      <div className="about-page">
        <h2>Sobre nosotros</h2>
        <UnicosHome />
        <LibretoSection />
        <CommunitySection />
      </div>
    </div>
  );
};

export default SobreNosotrosPage;

const LibretoSection = () => {
  return (
    <div className="libreto">
      <div className="unique-content-title">
        <h3>Libreto</h3>
      </div>
      <div className="libreto-container">
        <div className="libreto-texto">
          <p>
            <TextComponent text={libretoText1} />
          </p>
          <br />
          <p>
            <TextComponent text={libretoText2} />
          </p>
          <br />
          <p>
            <TextComponent text={libretoText3} />
          </p>
          <br />
          <p>
            <TextComponent text={libretoText4} />
          </p>
          <br />

          <Link
            to="/libreto-El-Cerro-de-San-Anton-Jose-Antonio-Barbera.pdf"
            target="_blank"
            download
          >
            Descargar Libreto
            <img className="link-arrow" src={LinkArrow} alt={"logo"} />
          </Link>
        </div>
        <div className="libreto-book">
          {/* <img src={LibretoBook} alt="" /> */}
          <ImageComponent classes="libreto-book-image" imgUrl={LibretoBook} />
        </div>
      </div>
    </div>
  );
};

const CommunitySection = () => {
  return (
    <div className="community">
      <div className="unique-content-title">
        <h3>
          La Junta de Gobierno de la Comunidad Pinares de San Antón{" "}
          <span style={{ whiteSpace: "nowrap" }}>-PSA-</span>
        </h3>
      </div>
      <div className="comunidad">
        <p>
          La Junta de Gobierno forma parte de los Órganos de Gobierno y
          Administración de la Comunidad, según se establece en el{" "}
          <strong>CAPÍTULO TERCERO</strong> de los Estatutos,{" "}
          <strong>Artículo 21º</strong> y siguientes, donde se desarrolla su
          composición, funciones, facultades, etc., y cuyo presidente actual es{" "}
          <strong>D. Ignacio de Torres Villagrá.</strong>
        </p>
        <br />
        <p>
          Es necesario recordar que esta Comunidad de Propietarios se constituyó
          en el año 1979, “asumiendo el mantenimiento y conservación de las
          obras de urbanización de la misma, como consecuencia de las
          circunstancias atípicas y singulares que concurrieron en su
          implantación y de los incumplimientos de las obligaciones urbanísticas
          imputables a la promotora de aquella” (Texto del informe de la
          Gerencia Municipal de Urbanismo, Expediente B2007-1010, sobre la
          recepción de las obras de urbanización).
        </p>
        <br />
        <p>
          La cesión municipal de viales, redes, etc. supuso un alivio económico
          y de responsabilidad para la economía de la Comunidad, pero por otro
          lado ha supuesto una falta de control sobre el mantenimiento básico
          que deben tener dichas redes, aún habiendo reconocido en su momento la
          propia Gerencia Municipal de Urbanismo el estado defectuoso y/o
          insuficiente de las mismas.
        </p>
        <br />
        <p>
          Por tanto, el caballo de batalla de esta Junta de Gobierno, como de
          otras anteriores, ha sido el de intentar por todos los medios que el
          Ayuntamiento de Málaga cumpla con sus obligaciones de mantener en buen
          estado todas las redes e infraestructuras de su competencia, así como
          que se cumpla estrictamente el Plan General de Ordenación Urbana en
          todos los proyectos de obra nueva.
        </p>
        <br />
        <p>
          Es preciso mencionar aquí también la ocupación y el trabajo personal,
          totalmente altruista, desde su etapa de antiguos presidentes hasta los
          momentos actuales, de personas como{" "}
          <strong>
            D. Alfonso Guerrero, D. Francisco Crespillo, Dña. Maribel Pozo, D.
            Manuel Flores,
          </strong>{" "}
          etc., sin otra recompensa que la compensación y satisfacción personal
          de ver que se respeta la naturaleza y esencia de la urbanización y su
          entorno, en el más amplio sentido de la palabra, como la conocieron
          hace ya más de cuarenta años.
        </p>
      </div>
    </div>
  );
};
