import React, { useEffect, useRef } from "react";
import { motion, useInView } from "framer-motion";

const ImageComponent = ({ classes, imgUrl }) => {
  const ref = useRef(null);
  const inView = useInView(ref);
  //   useEffect(() => {}, [inView]);
  return (
    <>
      <motion.img
        className={`${classes}`}
        ref={ref}
        initial={{ y: 200, opacity: 0 }}
        animate={inView ? { y: 0, opacity: 1 } : { y: 200, opacity: 0 }}
        transition={{ delay: 0.2, duration: 1 }}
        src={imgUrl}
      />
    </>
  );
};

export default ImageComponent;
