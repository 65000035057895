import React from "react";
import { motion } from "framer-motion";
import ImgBg from "../../media/recycling-oil.jpg";

const CardOil = () => {
  return (
    <motion.div
      initial={{ y: 20, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: -20, opacity: 0 }}
      transition={{ duration: 0.6 }}
      key="card-oil"
      className="services-content-images-image-card"
    >
      <img
        className="services-content-images-image-card-bg"
        src={ImgBg}
        alt=""
      />
    </motion.div>
  );
};

export default CardOil;
