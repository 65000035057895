import { motion } from "framer-motion";
import pageTransition from "../components/pageTransition";
import "../styling/no-page.scss";

const NoPage = () => {
  return (
    <motion.div
      initial={{ y: 40, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: 40, opacity: 0 }}
      transition={{ duration: 1 }}
    >
      <div className="no-page">
        <h1>404</h1>
        <big>Lo sentimos pero ésta página no existe.</big>
        <big>
          Para regresar a la página principal haga click
          <a href="/"> aqui</a>
        </big>
      </div>
    </motion.div>
  );
};

export default pageTransition(NoPage);
