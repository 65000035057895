import React from "react";
import ReactDOM from "react-dom/client";
import "./styling/index.scss";
import App from "./App";
import { BrowserRouter, Routes, Route } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    {/* <BrowserRouter> */}
    {/* <BrowserRouter basename="/psaweb"> */}
    <BrowserRouter basename="/">
      <Routes>
        <Route path="/*" element={<App />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);
