import React from "react";
import Image from "../media/vista-pinares-sobre-nosotros.jpg";
import { Link } from "react-router-dom";
import "../styling/about-home.scss";
import TextComponent from "./TextComponent";
import LinkArrow from "../media/link-arrow.svg";
import ImageComponent from "./ImageComponent";

const data = {
  section: "Sobre nosotros",
  title: ["Descubre el", " Monte San Antón"],
  text: [
    "La hacienda de Santa María de Buenavista, también conocida como la de San Antón, es una extensa propiedad que perteneció al clero desde 1496. Desde entonces hasta 1670, cuando fue cedida a la comunidad de Antoninos, pasó por manos de eremitas e instituciones religiosas.",
    "Este cerro destaca por su rica fauna y amplia flora, y su ubicación privilegiada frente al mar le proporciona un clima mediterráneo beneficioso. Rodeado por los arroyos Jabonero y Gálica, alcanza alturas de 468 metros, 502 metros y 381 metros en sus tres cimas. Desde estas cimas, se disfrutan hermosos paisajes, con vistas a los montes de la cercana costa africana en días despejados.",
    "Personajes como Edmond Boissier han explorado la zona, destacando la riqueza natural de San Antón y realizando estudios detallados de su flora en colaboración con farmacéuticos locales como Pablo Prolongo y Félix Haenseler.",
  ],
  button: {
    text: "Leer más",
  },
};

const SobreNosotrosHome = () => {
  return (
    <div id="aboutUs" className="about">
      <div className="wrapper">
        <h2>
          <TextComponent text={data.section} />
        </h2>
        <div className="about-content">
          <div className="about-content-title">
            {/* <div className="about-content-title-line"></div> */}
            <h3>
              <TextComponent text={data.title} />
            </h3>
          </div>
          <ImageComponent classes="about-content-image" imgUrl={Image} />

          <div className="about-content-text">
            {data.text.map((t, index) => {
              return (
                <React.Fragment key={`text-${index}`}>
                  <p>
                    <TextComponent text={t} />
                  </p>
                  <br></br>
                </React.Fragment>
              );
            })}

            <Link to="/sobre-nosotros">
              {data.button.text}
              <img className="link-arrow" src={LinkArrow} alt={"logo"} />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SobreNosotrosHome;
