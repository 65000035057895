import React, { useRef } from "react";
import TreesBg from "../media/trees-bg.svg";
import { ReactComponent as SecurityIcon } from "../media/security-icon.svg";
// import CareIcon from "../media/care-icon.svg";
import { ReactComponent as SightsIcon } from "../media/sights-icon.svg";
import { ReactComponent as AnimalsIcon } from "../media/animals-icon.svg";
import { ReactComponent as PlantsIcon } from "../media/plants-icon.svg";
import { ReactComponent as MentalHealthIcon } from "../media/mental-health-icon.svg";
import { ReactComponent as CareIcon } from "../media/care-icon.svg";
// import { Link } from "react-router-dom";
import { motion, useInView } from "framer-motion";

import "../styling/unicos-home.scss";
import TextComponent from "./TextComponent";

const data = {
  title: ["Lo que nos hace", " únicos"],
  text: [
    "El Cerro de San Antón, más conocido como Monte de San Antón es uno de los reductos naturales más significativos del municipio de Málaga. Está ubicado en su totalidad dentro del término municipal de Málaga capital, al norte de la conocida Barriada de El Palo.",
    "El Monte de San Antón, forma parte de los Montes de Málaga. Las dos cumbres características que conforman el paisaje de la zona son conocidas como Las Tetas de Málaga.",
  ],

  cards: [
    {
      icon: CareIcon,
      title: "Naturaleza",
      text: "Vivir rodeado de una naturaleza única no solo embellece la vida, sino que también mejora la salud mental y física al proporcionar aire fresco y reducir el estrés.",
    },

    {
      icon: PlantsIcon,
      title: "Flora",
      text: "La conexión con la diversidad botánica promueve un estilo de vida activo y cultivar un aprecio por la naturaleza, contribuyendo a la responsabilidad ambiental.",
    },
    {
      icon: AnimalsIcon,
      title: "Fauna",
      text: "La presencia de una fauna singular en nuestro entorno no solo despierta admiración estética, sino que también sustenta la riqueza de los ecosistemas.",
    },
    {
      icon: MentalHealthIcon,
      title: "Tranquilidad",
      text: "Favorece la concentración en tareas diarias, fortalece el bienestar emocional y cultiva un ambiente propicio para el crecimiento personal y la creatividad.",
    },
    {
      icon: SecurityIcon,
      title: "Seguridad",
      text: "La seguridad en la zona de residencia proporciona una sensación de bienestar, permitiendo que la mente se enfoque en actividades diarias sin preocupaciones constantes.",
    },
    {
      icon: SightsIcon,
      title: "Vistas",
      text: "Contemplar horizontes infinitos fomenta la serenidad mental y eleva la calidad de vida, creando un entorno propicio para la reflexión y la conexión con la belleza natural.",
    },
  ],
};

const UnicosHome = () => {
  const ref = useRef(null);
  const inView = useInView(ref);

  return (
    <div id="unique" className="unique">
      <div className="wrapper">
        <div className="unique-content">
          <img className="unique-content-bg" src={TreesBg} alt="" />
          <div className="unique-content-title">
            {/* <div className="unique-content-title-line"></div> */}
            <h3>
              <TextComponent text={data.title} />
            </h3>
          </div>
          <div className="unique-content-text">
            {data.text.map((t, index) => {
              return (
                <React.Fragment key={`card-${index}`}>
                  <p>
                    <TextComponent text={t} />
                  </p>
                  <br></br>
                </React.Fragment>
              );
            })}
          </div>
          <div className="unique-content-cards" ref={ref}>
            {data.cards.map((card, index) => {
              let SvgElement = card.icon;
              return (
                <React.Fragment key={`card-${card.title}`}>
                  <motion.div
                    initial={{ y: 80, opacity: 0 }}
                    animate={
                      inView ? { y: 0, opacity: 1 } : { y: 80, opacity: 0 }
                    }
                    transition={{ delay: 0.2 * index, duration: 1 }}
                    className="unique-content-cards-card"
                  >
                    <SvgElement className="unique-content-cards-card-image" />
                    <h5 className="unique-content-cards-card-title">
                      <TextComponent text={card.title} />
                    </h5>
                    <p className="unique-content-cards-card-text">
                      <TextComponent text={card.text} />
                    </p>
                  </motion.div>
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnicosHome;
