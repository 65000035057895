import React from "react";
import Img1 from "../media/quienes-somos1.jpg";
import Img2 from "../media/quienes-somos2.jpg";
import Img3 from "../media/quienes-somos3.jpg";
import Shape from "../media/quienes-somos-shape.svg";
import LinkArrow from "../media/link-arrow.svg";
import ImageComponent from "./ImageComponent";
import { Link } from "react-router-dom";
import "../styling/who-home.scss";
import TextComponent from "./TextComponent";

const data = {
  title: ["¿Quiénes", " somos?"],
  text: [
    "La razón de ser, el objetivo y el sentido de la existencia de la Comunidad de Propietarios ha sido y sigue siendo el de proteger y preservar el Monte de San Antón como pulmón de la ciudad para uso y disfrute de todos los comuneros y vecinos de la ciudad de Málaga, así como su gestión, mantenimiento y mejora en los aspectos que competen a la misma.",
    "Para ello, trabajamos día a día en mejorar la calidad de vida de la fauna y flora autóctona, la de los vecinos que conviven en ella y para el resto de personas que nos visitan por ocio, esparcimiento y modo de vida saludable.",
  ],
  button: {
    text: "Ver más sobre la comunidad",
  },
};

const imageMap = [
  { image: Img1, class: "image1" },
  { image: Shape, class: "shape1" },
  { image: Img2, class: "image2" },
  { image: Img3, class: "image3" },
  { image: Shape, class: "shape2" },
];

const QuienesSomosHome = () => {
  return (
    <div id="whoAreUs" className="who">
      <div className="wrapper">
        <div className="who-content">
          <div className="who-content-title">
            {/* <div className="who-content-title-line"></div> */}
            <h3>
              <TextComponent text={data.title} />
            </h3>
          </div>
          <div className="who-content-text">
            {data.text.map((t, index) => {
              return (
                <React.Fragment key={`text-h-${index}`}>
                  <p>
                    {" "}
                    <TextComponent text={t} />
                  </p>
                  <br></br>
                </React.Fragment>
              );
            })}

            <Link to="/galeria">
              {data.button.text}
              <img className="link-arrow" src={LinkArrow} alt={"logo"} />
            </Link>
          </div>
          <div className="who-content-images">
            {imageMap.map((im, index) => {
              return (
                <React.Fragment key={`text-im-${index}`}>
                  <ImageComponent
                    classes={`who-content-images-${im.class}`}
                    imgUrl={im.image}
                  />
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuienesSomosHome;
