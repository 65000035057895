import { motion } from "framer-motion";
import ContactoHome from "../components/ContactoHome";

const Contact = () => {
  return (
    <motion.div
      id="contact"
      initial={{ y: 40, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: 100, opacity: 0 }}
      transition={{ duration: 1 }}
    >
      <ContactoHome />
    </motion.div>
  );
};

export default Contact;
